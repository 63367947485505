const state = {
};

const getters = {
  // BESPOKE DENTAL-22
  // Use bulk stock api instead of seperate calls
  productStockEndpoint (state) {
    return state.productsArrayStockUrl;
  },
  // END BESPOKE DENTAL-22
  orderProductEndpoint (state) {
    return state.orderProductUrl;
  },
  productPriceEndpoint (state) {
    return state.productPriceUrl;
  },
  getProductsInListsEndpoint (state) {
    return state.customerList + '/GetProductLists';
  },
  addProductToListEndpoint (state) {
    return state.customerList + '/AddProduct';
  },
  removeProductFromListEndpoint (state) {
    return state.customerList + '/RemoveProduct';
  },
  productsFromList (state) {
    return state.customerList + '/GetProductsFromList';
  },
  elasticProductsEndpoint (state) {
    return state.elasticProducts;
  },
  productInformationEndpoint (state) {
    return state.productInformation;
  },
  seoInformationEndpoint (state) {
    return state.seoInformation;
  },
  productVariantsEndpoint (state) {
    return state.elasticProducts + '/GetByProductVariantKey'
  },
  customerListEndpoint (state) {
    return state.customerList;
  },
  shoppingCartEndpoint (state) {
    return state.shoppingCartUrl;
  },
  orderLinesEndpoint (state) {
    return state.shoppingCartUrl + '/GetOrderLines';
  },
  shoppingCartTotalsEndpoint (state) {
    return state.shoppingCartUrl + '/GetTotals';
  },
  shoppingCartTotalAndCountEndpoint (state) {
    return state.shoppingCartUrl + '/GetOrderLines';
  },
  updateOrderLineEndpoint (state) {
    return state.shoppingCartUrl + '/UpdateOrderLine';
  },
  deleteOrderLineEndpoint (state) {
    return state.shoppingCartUrl + '/DeleteOrderLine';
  },
  getCouponInfoEndpoint (state) {
    return state.shoppingCartUrl + '/GetCouponInfo';
  },
  addCouponEndpoint (state) {
    return state.shoppingCartUrl + '/AddCoupon';
  },
  removeCouponEndpoint (state) {
    return state.shoppingCartUrl + '/RemoveCoupon';
  },
  emptyShoppingCartEndpoint (state) {
    return state.shoppingCartUrl + '/EmptyShoppingCart';
  },
  productVariantDescriptionEndpoint (state) {
    return state.productVariantDescription;
  },
  relatedProductsEndpoint (state) {
    return state.relatedProductsUrl;
  },
  productVideosEndpoint (state) {
    return state.productVideosUrl;
  },
  productDocumentsEndpoint (state) {
    return state.productDocumentsUrl;
  },
  productImageBannersUrl (state) {
    return state.productImageBannersUrl;
  },
  bespokeProductFieldsEndpoint (state) {
    return state.bespokeFieldsUrl
  },
  bespokeEndpoints (state) {
    return state.bespokeEndpoints;
  }
};

const mutations = {
  setGlobalEndpoints (state, globalEndpointsObj) {
    Object.assign(state, globalEndpointsObj);
  }
};

const actions = {
  setGlobalEndpoints ({ commit }, globalEndpointsObj) {
    commit('setGlobalEndpoints', globalEndpointsObj);
  }
};

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};
