<template>
  <div class="cmp-product-price" v-if="showPrices" :class="parentClassRef">    
    <!-- BESPOKE DEN-292, DEN-323: display label instead of price if PRICE_ON_REQUEST is true and ignorePriceOnRequest is false -->
    <template v-if="!product.customBooleans.PRICE_ON_REQUEST || bespokeGlobalWs.ignorePriceOnRequest">
      <ul class="price-list flex-row-nowrap align-items-baseline">
        <li class="default">
          <span>{{ prices.price }}</span>
        </li>
        <li v-if="prices.isSalesAction" class="original salesaction">
          <span>{{ prices.basePrice }}</span>
        </li>
      </ul>
    </template>
    <template v-else>
      <span v-translation="{ type: 'label', code: 'lbl_price_on_request'}" class="price-on-request"></span>
    </template>
    <!-- END BESPOKE DEN-292 -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    prices: { type: Object, required: false },
    parentClassRef: { type: String, default: '', required: false },
    // BESPOKE DEN-458
    // Added product as prop
    product: { type: Object, required: false },
    // END BESPOKE DEN-458
  },
  computed: {
    // BESPOKE DEN
    // Added bespokeGlobalWs
    ...mapGetters(['showPrices', 'bespokeGlobalWs'])
    // END BESPOKE DEN
  }
}
</script>

<style>

</style>
