<template>
  <div class="cmp-elastic-product-list utlz-row utlz-reset blocks" :class="listClass">
    <div class="utlz-col-md-12">

      <div class="productlist-header">
        <h1 v-translation="{ type: 'title', code: 'title_last_debtor_prod' }"></h1>
        <div class="prod-counter">
          <p class="product-counter">{{ totalProducts }} producten</p>          
        </div>
      </div>

      <utlz-sorting-options :layoutType="listClass" :totalProducts="0" :pageRange="{}" @changeLayout="changeLayout($event)">
        <template v-slot:pagination>
          <div class="search-bar-wrapper">
            
            <input type="text" 
              :placeholder="'Zoek binnen producten'" 
              @keyup.enter="searchByQuery(searchText)" 
              v-model="searchText" 
              class="form-control" 
            />

            <a class="inputbutton btn search-button" @click="searchByQuery(searchText)"></a>
          </div>
        </template>
        
      </utlz-sorting-options>
      <div class="last-ordered-products cmp-elastic-product-list">
        <div class="list-items-wrapper utlz-row" v-if="debtorProducts != null">
          <utlz-product-list-item v-for="product in filteredDebtorProducts" :key="product.id" :product="product">
            
          </utlz-product-list-item>
        </div>
        <h2 v-if="debtorProducts != null && debtorProducts.length == 0">Er zijn geen producten gevonden</h2>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Product } from 'src/models/product.model';
import { getProductInformation } from 'src/services/product-service';
import ProductListItem from './../../components/webshop/products/product-list-item/ProductListItem.vue';
import SortingOptions from './../../components/webshop/sorting-options/SortingOptions.vue';
export default {
  components: {
    'utlz-product-list-item': ProductListItem,
    'utlz-sorting-options': SortingOptions
  },
  data() {
    return {
      listClass: 'blocks',
      searchText: '',
      debtorProductCodes: [],
      debtorProducts: [],
      filteredDebtorProducts: [],
      totalProducts: 0
    }
  },
  methods: {
    changeLayout(listClass) {
      this.listClass = listClass;
    },
    searchByQuery(searchText) {
      if (searchText) {
        this.filteredDebtorProducts = this.debtorProducts.filter(product => {
          return product.shortDescription.toLowerCase().includes(searchText.toLowerCase()) || 
            product.id.includes(searchText.toLowerCase());
        });
      } else {
        this.filteredDebtorProducts = this.debtorProducts;
      }
      this.totalProducts = filteredDebtorProducts.length;
    },
    getDebtorProducts () {
      axios.post('/Bespoke/WebMethods/DentalWebMethods.aspx/LastDebtorOrders', {}).then(
        res => {          
          var config = { 
            prodCodes: res.data.d, 
            getStock: false, 
            getCustomerLists: false, 
            getSeoInformation: false 
          };

          getProductInformation(config).then(productResults => {
            productResults.retrievedPrices.then( _ => {          
              var products = productResults.products;
              this.debtorProducts = products;
              this.filteredDebtorProducts = [...products];
              this.totalProducts = this.filteredDebtorProducts.length;
            });

          });
        }
      );
    }
  },
  created() {
    this.getDebtorProducts();
  }
}
</script>

<style>

</style>