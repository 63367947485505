import { formatPrice } from 'src/utilities/helpers';

export class Product {
  constructor (product) {
    if (product === undefined) {
      this.setEmptyProduct();
    } else {
      this.setProduct(product);
      if (this.images) {
        this.images.unshift(this.imageUrl);
      } else {
        this.images = [this.imageUrl];
      }
    }
  }

  setProduct (product) {
    this.id = product.id;
    this.shortDescription = product.shortDescription;
    this.subTitle = product.subTitle;
    this.longDescription = product.longDescription;
    this.properties = product.properties;
    this.targetUrl = product.targetUrl;
    this.imageUrl = product.imageUrl;
    this.images = product.images;
    this.brand = product.brand;
    this.units = product.units;
    this.saleUnit = product.saleUnit;
    this.discountGroup = product.discountGroup;
    this.prices = [];
    this.units = this.setUnits(product.units);
    this.stock = { stockTotal: null };
    this.variantKey = product.variantKey;
    this.productVariants = [];
    this.customStrings = product.customStrings;
    this.customDecimals = product.customDecimals;
    this.customBooleans = product.customBooleans;
    this.customDateTimes = product.customDateTimes;
    this.includedInCustomerLists = [];
    this.imageAltText = product.id;
    this.imageTitleText = product.id;
    this.productImageBanners = [];
    // Cloudinary images
    this.imageUrlExtraSmall = this.getCloudinaryUrl('xs', product.imageUrl);
    this.imageUrlSmall = this.getCloudinaryUrl('sm', product.imageUrl);
    this.imageUrlMedium = this.getCloudinaryUrl('md', product.imageUrl);
    this.imageUrlXLarge = this.getCloudinaryUrl('xl', product.imageUrl);
    this.cloudinaryImages = [];
    // END BESPOKE KOB
  }

  setEmptyProduct () {
    this.id = 'NOTLOADED';
    this.shortDescription = '';
    this.subTitle = '';
    this.longDescription = '';
    this.properties = '';
    this.targetUrl = '';
    this.imageUrl = '';
    this.images = [];
    this.brand = '';
    this.variantKey = '';
    this.units = [];
    this.saleUnit = '';
    this.discountGroup = '';
    this.prices = [];
    this.units = null;
    this.stock = { stockTotal: null };
    this.variantKey = '';
    this.productVariants = [];
    this.customStrings = {};
    this.customDecimals = {};
    this.customBooleans = {};
    this.customDateTimes = {};
    this.includedInCustomerLists = [];
    this.imageAltText = '';
    this.imageTitleText = '';
  }

  setStock (stock) {
    this.stock = stock;
  }

  setSeoInformation (seoInfo) {
    this.imageAltText = seoInfo.ImageAltText;
    this.imageTitleText = seoInfo.ImageTitleText;    
  }

  setProductImageBanners (banners) {
    const computedBanners = [
      { position: 'top-left', values: [], show: false },
      { position: 'top-right', values: [], show: false },
      { position: 'bottom-right', values: [], show: false },
      { position: 'bottom-left', values: [], show: false }
    ];
    banners.forEach(banner => {
      computedBanners[banner.Location].values.push(banner);
      computedBanners[banner.Location].show = true;
    });
    this.productImageBanners = computedBanners;
  }

  setUnits (units) {
    if (units) {
      return units.sort((a, b) => (a.quantity > b.quantity) ? 1 : -1)
    } else return null;
  }

  setProductVariants (productVariants) {
    this.productVariants = productVariants;
  }

  setCustomerLists (customerList) {
    this.includedInCustomerLists = customerList;
  }

  // BESPOKE DEN
  // Added priceIncrease
  setPrices (prices, priceIncrease) {
    let factor = 1 + (priceIncrease / 100);
    if (prices !== undefined) {
      prices.forEach(priceObj => {
        priceObj.rawPrice = priceObj.price;
        priceObj.rawBasePrice = priceObj.basePrice;
        priceObj.price = formatPrice(priceObj.price * factor);
        priceObj.basePrice = formatPrice(priceObj.basePrice);
        priceObj.quantity = priceObj.quantity === 0 ? 1 : priceObj.quantity;
      });
    }
    this.prices = prices;
  }
  // END BESPOKE DEN

  // BESPOKE DEN: Added methods to update products customerslist
  // so we can update local state directly through store
  addProductToCustomerList (listId) {
    this.includedInCustomerLists.push(listId);
  }

  removeProductFromCustomerList (listId) {
    const index = this.includedInCustomerLists.indexOf(listId);
    this.includedInCustomerLists.splice(index, 1);
  }
  // END BESPOKE DEN


  // BESPOKE DEN
  // Handles cloudinary images
  getCloudinaryUrl (size, imageUrl) {
    var imgUrl = imageUrl;
    const getters = window.vuexStore.$store.getters;
    const dimensions = getters.bespokeGlobalWs.cloudinaryImgSizes.split(',');

    let imgWidth;
    switch(size) {
      case 'xs':
        imgWidth = dimensions[0];
        break;
      case 'sm':
        imgWidth = dimensions[1];
        break;
      case 'md':
        imgWidth = dimensions[2];
        break;
      case 'lg':
        imgWidth = dimensions[3];
        break;
      case 'xl':
        imgWidth = dimensions[4];
        break;
    }

    if (this.customStrings.SUPPLIER_ID !== "") {
      if (this.customStrings.IMAGE_URL !== "" || this.customStrings.IMAGE_URL !== undefined) {
        this.imageUrl = this.customStrings.IMAGE_URL;
        imgUrl = this.customStrings.IMAGE_URL;
      }
    }

    let url = imgUrl.replace('UTI_IMG_SIZE', imgWidth);
    let urlId = url.replace(/UTI_IMGID/g, this.id);

    return urlId.replace(/,/g, '%2c');
  }

  setCloudinaryUrls () {
    this.images.forEach(image => {
      this.cloudinaryImages.push({ 
        small: this.getCloudinaryUrl('sm', image),
        large: this.getCloudinaryUrl('xl', image),
      });
    });
  }
  // END BESPOKE DEN

}


