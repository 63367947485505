<template>
  <div class="cmp-shopping-cart-overview utlz-reset utlz-row" :class="tableClasses">

    <!-- BESPOKE DEN-557: Show unavailable products that are being removed from the order -->
    <template v-if="showProductsUnavailablePrompt">
      <div class="cmp-prompt unavail-products">
        <div class="full-screen-fader flex-column justify-content-center">
          <div class="utlz-container">
            <div class="utlz-row">
              <div class="utlz-col-lg-8 utlz-offset-lg-2">
                <!-- <span class="close-button">
                  <i aria-hidden="true" class="fa fa-times-circle"></i>
                </span> -->
                <div class="prompt-content">
                  <div class="delete-product-prompt utlz-row">
                    <div class="utlz-col-12">
                      <h2><span v-translation="{ code: 'title_products_not_avail', type: 'title'}"></span></h2>
                      <div class="subtitle" v-translation="{ type: 'text', code: 'text_prod_not_avail' }"></div>
                    </div>
                    <div class="utlz-col-12">
                      <div class="utlz-row" v-for="product in unavailableProducts" :key="product.id">
                        <div class="utlz-col-2">
                          <img :src="product.imageUrl" />
                        </div>
                        <div class="utlz-col-10">
                          {{ product.shortDescription }}
                        </div>
                      </div>
                    </div>
                </div>
                <div class="action-btns full-width-buttons-mobile">
                  <template v-if="!unavailableProductsDeleted">
                    <div class="loader-wrapper">
                      <span class="loader"></span>
                      <span v-translation="{ code: 'label_removing_products', type: 'label' }"></span>
                    </div>
                  </template>
                  <template v-else>
                    <a @click="confirmDeletedProducts()" v-translation="{ type: 'button', code: 'button_prompt_confirm'}" class="button theme-primary confirm"></a>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    </template>
    <!-- END BESPOKE DEN -->


    <transition name="fadein">
      <utlz-prompt
        v-if="showDeleteProductPrompt"
        :payload="promptPayload.lineId"
        @confirm="deleteOrderLineFromCart($event)"
        @cancel="cancelPrompt()"
      >

      <template v-slot:prompt-content>
        <div class="delete-product-prompt utlz-row">
          <div class="utlz-col-12">
            <h2>
              <span v-translation="{ type: 'title', code: 'title_prompt_delete_from_cart' }"></span>
            </h2>
          </div>
          <div class="utlz-col-3 img">
            <!-- BESPOKE DEN: Added cloudinary image -->
            <img :src="promptPayload.product.imageUrl ? promptPayload.product.imageUrlMedium : '/documents/productimages/not-available-small.jpg'" />
            <!-- END BESPOKE DEN -->
          </div>
          <div class="utlz-col-9">
            <h3>
              {{ promptPayload.product.shortDescription }}
            </h3>
          </div>
        </div>
      </template>

      </utlz-prompt>
    </transition>

    <transition name="fadein">
      <utlz-prompt
        v-if="showEmptyCartPrompt"
        @confirm="emptyCart()"
        @cancel="cancelEmptyCartPrompt()"
      >

      <template v-slot:prompt-content>
        <h2 v-translation="{ type: 'text', code: 'txt_confirm_clear_shopping' }"></h2>
      </template>

      </utlz-prompt>
    </transition>

    <transition name="fadein">
      <utlz-static-modal v-if="validatingStock">
        <template v-slot:static-modal-content>
          <div class="validate-stock-modal utlz-row">
            <div class="utlz-col-12 flex-row-nowrap align-center just-center">
              <span class="loader"></span>
              <h2>
                <span v-translation="{ type: 'label', code: 'label_validating_stock' }"></span>
              </h2>
            </div>
          </div>
        </template>
      </utlz-static-modal>
    </transition>


    <!-- BESPOKE DEN-457: Added prompt to swap product with alternative -->
    <transition name="fadein">
      <utlz-prompt
        v-if="showSwapProductPrompt"
        :payload="swapProductPayload"
        @confirm="confirmSwapProduct()"
        @cancel="cancelSwapProductPrompt()"
      >

      <template v-slot:prompt-content>
        <div class="swap-product-prompt utlz-row">
          <div class="utlz-col-12">
            <h2 v-translation="{ type: 'title', code: 'title_exchange_product'}"></h2>
          </div>          
          <div v-for="(product) in swapProductPayload.products" :key="product.id" class="utlz-col-sm-6 product-block">            
            <img :src="product.imageUrl"/>
            <!-- BESPOKE DEN: Added cloudinary image -->
            <img :src="product.imageUrl ? product.imageUrlExtraSmall : '/documents/productimages/not-available-small.jpg'" />
            <!-- END BESPOKE DEN -->
            <div class="text-wrapper">
              <h3>{{ product.shortDescription }}</h3>
              <div class="product-code">
                  <span v-translation="{ type: 'label', code: 'label_product_code' }"></span>
                  <span class="value">{{ product.id }}</span>
              </div>
              <utlz-product-price 
                v-if="product.prices.length"
                :product="product"
                :prices="product.prices[0]">
              </utlz-product-price>
            </div>
          </div>
        </div>
      </template>

      </utlz-prompt>
    </transition>
    <!-- END BESPOKE DEN-457 -->


    <transition-group name="fade" tag="div" class="ph-wrapper utlz-col-12">
      <template v-if="loadingOrderLines">
        <div v-for="index in 4" :key="index" class="ph-silhouette orderlist-row">
          <span class="silhouette-img"></span>
          <div class="ph-paragraph">
            <span class="silhouette-line"></span>
            <span class="silhouette-line"></span>
          </div>
        </div>
      </template>
    </transition-group>

    <div v-if="shoppingCartEmpty" class="empty-cart-message col-md-12">
      <h2 v-translation="{ type: 'title', code: 'title_shopping_cart_empty' }"></h2>
    </div>

    <transition name="fade">
      <div v-if="showOrderLines && screenWidth >= 880" class="orderline-header utlz-col-md-12">
        <div class="orderlines-wrapper">
          <div v-if="!hideShoppingCartImages" class="prod-img orderline-cell"></div>
          <div class="prod-desc orderline-cell">
            <span v-translation="{ type: 'label', code: 'label_header_prod_desc' }"></span>
          </div>

          <!-- BESPOKE DEN: Added orderline comment header -->
          <div v-if="useOrderLineComments" class="orderline-comment">
            <span v-translation="{ type: 'label', code: 'label_orderline_comment_ph' }"></span>
          </div>
          <!-- END BESPOKE DEN -->

          <div class="prod-price orderline-cell">
            <span v-translation="{ type: 'label', code: 'label_header_prod_price' }"></span>
          </div>
          <!-- BESPOKE DEN-452: Disabled stock column -->
          <!-- <div class="prod-stock orderline-cell">
            <span v-translation="{ type: 'label', code: 'label_header_prod_stock' }"></span>
          </div> -->
          <!-- END BESPOKE DEN-452 -->

          <div class="order-product orderline-cell">
            <span v-translation="{ type: 'label', code: 'label_header_prod_quantity' }"></span>
          </div>
          <div class="prod-total orderline-cell">
            <span v-translation="{ type: 'label', code: 'label_header_prod_total' }"></span>
          </div>
          <div class="delete-prod orderline-cell">
          </div>
        </div>
      </div>
    </transition>

    <transition-group name="fade" tag="div" class="orderline-table utlz-col-md-12">
      <template v-if="showOrderLines">
        <div v-for="orderLine in orderLines" :key="orderLine.lineId" class="orderline-row">

          <div v-if="!hideShoppingCartImages" class="prod-img orderline-cell">
            <a :href="orderLine.product.targetUrl">
              <!-- BESPOKE: Added best buy logo -->
                <div class="icons-container">
                  <img v-if="orderLine.product.customBooleans.IS_BEST_BUY" :src="bespokeGlobalWs.bestBuyImgUrl" class="best-buy-logo product-icon" />
                  <!-- END BESPOKE -->
                  <!-- BESPOKE: Added outlet logo -->
                  <img v-if="orderLine.product.customBooleans.IS_OUTLET_PRODUCT" src="/Bespoke/Images/Outlet/icoon-outlet.png" class="outlet-logo product-icon">
                  <!-- END BESPOKE -->
                </div>
                <div class="icons-container icons-right">
                  <img v-if="orderLine.product.customBooleans.IS_GREEN_BUY" :src="bespokeGlobalWs.greenBuyIcon" class="green-buy-logo product-icon">
                </div>
                <!-- END BESPOKE -->
              <!-- BESPOKE DEN: Added cloudinary image -->
                <img :src="orderLine.product.imageUrl ? orderLine.product.imageUrlSmall : '/documents/productimages/not-available-small.jpg'" />
              <!-- END BESPOKE DEN -->
            </a>
          </div>

          <div class="prod-desc orderline-cell">
            <a :href="orderLine.product.targetUrl">
              <h3 class="prod-title body-text">{{ orderLine.product.shortDescription }}</h3>
              <span class="prod-code">{{ orderLine.product.id }}</span>
            </a>            
          </div>

          <!-- BESPOKE DEN: Moved desktop orderline comment here -->
          <template v-if="useOrderLineComments && screenWidth >= 880">
            <div class="orderline-comment">              
            <utlz-order-line-comment
              :orderLine="orderLine"
              ></utlz-order-line-comment>
            </div>
          </template>
          <!-- END BESPOKE DEN -->

          <!-- BESPOKE DEN: Added available as best buy button -->
          <div class="avail-as-best-buy">
            <a class="" @click="swapProduct(orderLine)">
              <img v-if="!orderLine.product.customBooleans.IS_BEST_BUY && orderLine.product.customStrings.BEST_BUY_PROD_CODE" :src="bespokeGlobalWs.bestBuyAvailIcon" class="best-buy-avail-logo product-icon">
            </a>
          </div>
          <!-- END BESPOKE DEN -->

          <template v-if="showPrices">
            <div class="prod-price orderline-cell" v-if="orderLine.product.prices.length">
              <!-- BESPOKE DEN-458: Added :product="orderLine.product" -->
              <utlz-product-price
                :product="orderLine.product"
                :prices="orderLine.product.prices[0]"
              ></utlz-product-price>
              <!-- END BESPOKE DEN-458 -->
            </div>
            <div v-else class="stock-status-wrapper text-placeholder pulse">
              <span class="loader"></span>
            </div>
          </template>
          <template v-else>
            <div class="prod-price"></div>
          </template>
          <!-- BESPOKE DEN-452: Disabled stock column -->
          <!-- <div class="prod-stock orderline-cell">
            <utlz-product-stock
              :stock="orderLine.product.stock"
              :stockTotal="orderLine.product.stock"
            ></utlz-product-stock>
          </div> -->
          <!-- END BESPOKE DEN-452 -->

          <div class="order-product orderline-cell">
            <span v-if="loading" class="loader"></span>
            <utlz-order-product
              v-if="orderLine.product.stock !== null"
              :product="orderLine.product"
              :initialQuantity="orderLine.quantity"
              :initialUnitCode="orderLine.unitCode"
              :useOrderMode="false"
              :totalOrderLinesQuantity="orderLine.totalOrderLinesQuantity"
              @quantityChanged="quantityChanged($event, orderLine)"
              @unitsChanged="unitsChanged($event, orderLine)"
            ></utlz-order-product>
          </div>

          <div class="prod-total orderline-cell">
            <span>{{ orderLine.totals.price }}</span>
          </div>

          <div class="delete-prod orderline-cell">
            <span class="close-button small grey" @click="promptDelete(orderLine)">
              <i class="fa fa-times-circle" aria-hidden="true"></i>
            </span>
          </div>

          <template v-if="useOrderLineComments && screenWidth < 880">
            <div class="mobile-order-comment">
              <utlz-order-line-comment
                :orderLine="orderLine"
              ></utlz-order-line-comment>
            </div>
          </template>

        </div>
      </template>

    </transition-group>

    <template v-if="showEmptyShoppingCart && !shoppingCartEmpty && !loadingOrderLines">
      <div class="utlz-col-md-12 clear-cart-col">
        <a @click="showEmptyCartPrompt = true" v-translation="{ type: 'button', code: 'btn_clear_shopping_cart' }" class="button small outline clear-cart"></a>
      </div>
    </template>

    <div id="stock-warning" class="utlz-col-md-6">
      <transition name="fade">
        <template v-if="invalidOrderLines.length">
          <div class="stock-warning">
            <div class="stock-warning-wrapper">
              <h2 v-translation="{ type: 'title', code: 'title_shopping_cart_stock_warning' }"></h2>
              <ul>
                <li v-for="orderLine in invalidOrderLines" :key="orderLine.lineId">
                  <i class="fa fa-caret-right" aria-hidden="true"></i><span>{{ orderLine.product.shortDescription }}</span>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </transition>

      <utlz-coupons v-if="useCoupons && showOrderLines"></utlz-coupons>

    </div>

    <div class="utlz-col-md-6">
      <transition name="fade">
        <utlz-shopping-cart-totals
          v-if="showOrderLines"
          :format="shoppingCartTotalsFormat"
        ></utlz-shopping-cart-totals>
      </transition>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Prompt from 'src/components/ui/prompt/Prompt.vue';
import StaticModal from 'src/components/ui/static-modal/StaticModal.vue';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import ProductStock from 'src/components/webshop/product-stock/ProductStock.vue';
import OrderProduct from 'src/components/webshop/order-product/OrderProduct.vue';
import Coupons from 'src/components/webshop/coupons/Coupons.vue';
import OrderLineComment from 'src/components/webshop/order-product/blocks/OrderLineComment.vue';
import ShoppingCartTotals from 'src/components/webshop/shopping-cart/ShoppingCartTotals.vue';
import _ from 'lodash';

// BESPOKE DEN
import { getProductInformation } from 'src/services/product-service';
// END BESPOKE DEN

export default {
  components: {
    'utlz-prompt': Prompt,
    'utlz-static-modal': StaticModal,
    'utlz-product-price': ProductPrice,
    'utlz-product-stock': ProductStock,
    'utlz-order-product': OrderProduct,
    'utlz-coupons': Coupons,
    'utlz-order-line-comment': OrderLineComment,
    'utlz-shopping-cart-totals': ShoppingCartTotals
  },
  data () {
    return {
      loading: false,
      showDeleteProductPrompt: false,
      promptPayload: null,
      showEmptyCartPrompt: false,
      // BESPOKE DEN-457
      swapProductPayload: {
        products: [],
        orderLine: null
      },
      showSwapProductPrompt: false,
      swapProductMessage: window.vue.translations.label.label_product_swapped_msg
      // END BESPOKE DEN-457
    }
  },
  computed: {
    // BESPOKE DEN
    // Added bespokeGlobalWS, showProductsUnavailablePrompt
    ...mapGetters(['screenWidth', 'showStock', 'useCoupons', 'useOrderLineComments', 'useMinimumOrderQuantity', 'hideShoppingCartImages', 'showEmptyShoppingCart', 'bespokeGlobalWs', 'showPrices']),
    ...mapGetters(
      'shoppingCart', [
      'orderLines', 
      'invalidOrderLines', 
      'loadingOrderLines', 
      'orderLinesTotal', 
      'minimumOrderQuantity', 
      'shoppingCartEmpty', 
      'shoppingCartTotalsFormat', 
      'validatingStock', 
      'showProductsUnavailablePrompt', 
      'unavailableProducts',
      'unavailableProductsDeleted'
    ]),
    showOrderLines () {
      return this.orderLines.length > 0 && this.orderLines[0].product !== null;
    },
    tableClasses () {
      let classes = '';
      if (!this.showStock) {
        classes += 'hide-stock ';
      }
      if (this.hideShoppingCartImages) {
        classes += 'hide-images ';
      }
      return classes;
    }
  },
  methods: {
    // BESPOKE DEN
    // Added addToCart, reloadShoppingCartLines, confirmDeletedProducts
    ...mapActions('shoppingCart', ['initShoppingCart', 'updateOrderLineQuantity', 'deleteOrderLine', 'emptyShoppingCart', 'addToCart', 'getOrderLines', 'deleteOrderLine', 'reloadShoppingCartLines', 'confirmDeletedProducts']),
    // END BESPOKE DEN
    quantityChanged ({ computedQuantity }, orderLine) {
      const payload = {
        Comments: orderLine.comments,
        ProductId: orderLine.productId,
        LineId: orderLine.lineId,
        Quantity: computedQuantity.toString(),
        UnitCode: orderLine.unitCode,
        IsFree: orderLine.isFree
      };
      this.updateOrderLineQuantity(payload).then(res => {
      });
    },
    unitsChanged ({ unitCode, quantity, computedQuantity }, orderLine) {
      const payload = {
        Comments: orderLine.comments,
        ProductId: orderLine.productId,
        LineId: orderLine.lineId,
        Quantity: computedQuantity.toString(),
        UnitCode: unitCode,
        IsFree: orderLine.isFree
      };
      this.updateOrderLineQuantity(payload).then(res => {
      });
    },
    promptDelete (orderLine) {
      this.showDeleteProductPrompt = true;
      this.promptPayload = orderLine;
    },
    cancelPrompt () {
      this.showDeleteProductPrompt = false;
      this.promptPayload = null;
    },
    deleteOrderLineFromCart (orderLineId) {
      this.deleteOrderLine(orderLineId);
      this.showDeleteProductPrompt = false;
      this.promptPayload = null;
    },
    cancelEmptyCartPrompt () {
      this.showEmptyCartPrompt = false;
    },
    emptyCart () {
      this.showEmptyCartPrompt = false;
      this.emptyShoppingCart();
    },
    // BESPOKE DEN-457
    swapProduct (orderLine) {            
      const altProdCode = orderLine.product.customStrings.BEST_BUY_PROD_CODE;
      if (altProdCode) {
        getProductInformation({ prodCodes: [altProdCode], getStock: false, getCustomerLists: false, getSeoInformation: false })
          .then(res => {
            this.swapProductPayload = {
              products: [orderLine.product, res.products[0]],
              orderLine: orderLine
            }         
            this.showSwapProductPrompt = true;
          });          
      }
    },
    cancelSwapProductPrompt () {
      this.showSwapProductPrompt = false;
    },
    confirmSwapProduct () {
      const orderLineId = this.swapProductPayload.orderLine.lineId;

      this.addToCart({
        prod_code: this.swapProductPayload.products[1].id,
        ord_qty: this.swapProductPayload.orderLine.quantity,
        unit_code: "",
        prod_comment: ''
      }).then(addedToCart => {
        if (addedToCart) {          
          $('#order_message').css("display", "none");          
          window.updateOrderMessage(this.swapProductMessage);
          this.deleteOrderLine(orderLineId).then(orderLineDeleted => {
            if (orderLineDeleted) {
              this.reloadShoppingCartLines();              
            }
          });
        }
      });

      this.showSwapProductPrompt = false;
      this.swapProductPayload = {
        products: [],
        orderLineId: ''
      };
    }
    // END BESPOKE DEN-457
  },
  created () {
    this.initShoppingCart();
    this.quantityChanged = _.debounce(this.quantityChanged, 500);
  }
}
</script>

<style>

</style>
