<template>
  <div class="cmp-stock-quantities container-fluid" :class="{'detail-page': onDetailPage}">

    <template v-if="retrievingData">
      <div class="loader"></div>
    </template>

    <template v-if="!retrievingData">
      <div v-if="dataRetrieved" class="form-group row">
        <div class="quantity-line current-stock col-md-6">
          <label v-translation="{ type: 'label', code: 'lbl_stock_quantities_stock'}"></label>
          <input
            @change="updateStockQuantity('currentStock')"
            ref="currentStock"
            v-model="formFields.currentStock" type="number" min="0"
            :class="{
              'out-of-stock': parseInt(formFields.currentStock) <= parseInt(formFields.minStock),
              'low-stock': parseInt(formFields.currentStock) <= (parseInt(formFields.minStock) + 2),
              }"
            class="feedback-input" tabindex="-1">
        </div>

        <div class="quantity-line in-nota col-md-6">
          <label v-translation="{ type: 'label', code: 'lbl_stock_quantities_nota'}"></label>
          <input
            @change="updateStockQuantity('notaStock')"
            ref="notaStock"
            v-model="formFields.notaStock" type="number" min="0"
            :class="{'invalid': formFields.notaStock < 0 || formFields.notaStock === '' }"
            class="feedback-input" tabindex="-1">
        </div>

          <div class="quantity-line min-stock col-md-6">
              <label v-translation="{ type: 'label', code: 'lbl_stock_quantities_min_stock'}"></label>
              <input
                @change="updateStockQuantity('minStock')"
                ref="minStock"
                v-model="formFields.minStock" type="number" min="0"
                :class="{'invalid': formFields.minStock < 0 || formFields.minStock === '' }"
                class="feedback-input" tabindex="-1">
          </div>

          <div class="quantity-line max-stock col-md-6">
              <label v-translation="{ type: 'label', code: 'lbl_stock_quantities_max_stock'}"></label>
              <input
                @change="updateStockQuantity('maxStock')"
                ref="maxStock"
                v-model="formFields.maxStock" type="number" min="0"
                :class="{'invalid': formFields.maxStock < 0 || formFields.maxStock === '' }"
                class="feedback-input" tabindex="-1">
          </div>
      </div>      
      <span v-else v-translation="{ type: 'label', code: 'label_data_not_retrieved'}" class="small-text"></span>

      <template v-if="onDetailPage">
        <div class='row'>
          <div class='product-settings col-md-6'>
            <span v-translation="{ type: 'title', code: 'title_product_settings'}"></span>
            <span v-translation="{ type: 'label', code: 'label_stock_unit_size'}"></span>
            <input
              @change="updateStockQuantity('unitQuantity')"
                ref="unitQuantity"
                v-model="formFields.unitQuantity" type="number" min="0"
                :class="{'invalid': formFields.unitQuantity < 0 || formFields.unitQuantity === '' }"
                class="feedback-input" tabindex="-1">
          </div>          
        </div>            
      </template>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import { Product } from 'src/models/product.model';
export default {
  props: {
    product: { type: Product, required: true },
    onDetailPage: { type: Boolean, required: false, default: false }
  },
  data () {
    return {
      stockQuantityUrl: '/Bespoke/WebMethods/DentalWebMethods.aspx/getStockQuantities',
      updateStockQuantityUrl: '/Bespoke/WebMethods/DentalWebMethods.aspx/updateStockQuantities',
      retrievingData: true,
      dataRetrieved: false,
      formFields: {
        currentStock: 0,
        notaStock: 0,
        minStock: 0,
        maxStock: 0
      }
    }
  },
  methods: {
    getStockQuantity () {
      axios.post(this.stockQuantityUrl, { productCode: this.product.id }).then(res => {
        this.retrievingData = false;
        const stockData = res.data.d;
        if (stockData) {
          this.formFields = stockData;
          this.dataRetrieved = true;
        } else {
          this.dataRetrieved = false;
        }
      });
    },
    updateStockQuantity (stockType) {
      if (this.formFields[stockType] < 0) {
        this.formFields[stockType] = 0;
      }

      // Correct minStock to never exceed maxStock
      if (stockType === 'minStock') {
        if (this.formFields.minStock > this.formFields.maxStock) {
          this.formFields.minStock = this.formFields.maxStock;
        }
      } else if (stockType === 'maxStock') {
        if (this.formFields.maxStock < this.formFields.minStock) {
          this.formFields.maxStock = this.formFields.minStock;
        }
      }

      const payload = {
        productCode: this.product.id,
        stockType: stockType,
        stockValue: this.formFields[stockType]
      };
      axios.post(this.updateStockQuantityUrl, payload).then(res => {
        if (res.data.d.stockUpdated) {
          this.$refs[stockType].classList.add('update-success');
          setTimeout(() => {
            this.$refs[stockType].classList.remove('update-success');
          }, 1500);
        }
      });
    }
  },
  created () {
    this.getStockQuantity();
  }
}
</script>

<style>
</style>
