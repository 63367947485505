<template>
  <div class="cmp-product-options utlz-reset">
    <div class="options-list-wrapper"  @mouseover="hover = true" @mouseleave="hover = false">
    <span class="options-list-btn btn-u">Opties<i class="fa fa-angle-down"></i></span>
    <div class="options-list" v-if="showMenu">

      <a @click="printBarcode()" class="option-btn barcode-btn add">
        <i class="fa fa-barcode" aria-hidden="true"></i>
        <span v-translation="{ type: 'button', code: 'link_barcode' }"></span>
      </a>

      <a @click="toggleCatalog()" class="option-btn catalog-btn" :class="{ 'add': !this.productInCatalog, 'remove': this.productInCatalog }">
        <template v-if="!this.productInCatalog">
          <i class="fa fa-clipboard" aria-hidden="true"></i>
          <span v-translation="{ type: 'button', code: 'link_addcatalog' }"></span>
        </template>
        <template v-else>
          <i class="fa fa-clipboard" aria-hidden="true"></i>
          <span v-translation="{ type: 'button', code: 'link_removecatalog' }"></span>
        </template>
      </a>

      <transition name="fade-dental">
        <template v-if="!this.productInCustomerStock">
          <a @click="openAddToStockModal()" class="option-btn stock-btn add">
            <i class="fa fa-codepen" aria-hidden="true"></i>
            <span v-translation="{ type: 'button', code: 'link_addstock' }"></span>
          </a>
        </template>
        <template v-else>
          <a @click="openRemoveFromStockModal()" class="option-btn stock-btn remove">
            <i class="fa fa-codepen" aria-hidden="true"></i>
            <span v-translation="{ type: 'button', code: 'link_removestock' }"></span>
          </a>
        </template>
      </transition>
      </div>
    </div>

    <!-- Catalog modal -->
    <transition name="fade-dental">
    <template v-if="showCatalogModal">
      <div class="dental-modal-wrapper">
      <div @click="showCatalogModal = false" class="dental-modal-fade"></div>
      <div class="catalog-modal dental-modal container-fluid">

        <div class="utlz-row">

          <div class="dental-modal-header utlz-col-md-12">
            <h1 v-translation="{ type: 'title', code: 'title_instat_remove'}"></h1>
          </div>

          <div class="col-md-12 dental-modal-body">
            <div class="utlz-row">
              <div class="utlz-col-sm-4 img-col">
                <figure class="img-square prod-img">
                  <img class="img-fluid" :src="product.imageUrl ? product.imageUrl : '/documents/productimages/not-available-large.jpg'" />
                </figure>
              </div>
              <div class="utlz-col-sm-8">
                <div class="product-information">
                  <span>{{ product.id }}</span>
                  <h2>{{ product.shortDescription }}</h2>
                </div>
                <div class="prompt">
                  <h3 v-translation="{ type: 'text', code: 'text_remove_product' }"></h3>
                  <a
                    @click="showCatalogModal = false"
                    v-translation="{ type: 'button', code: 'button_dentalair_cancel_product' }"
                    class="btn-u cancel">
                  </a>
                  <a
                    @click="removeFromCatalog()"
                    v-translation="{ type: 'button', code: 'button_dentalair_remove_product' }"
                    class="btn-u confirm">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      </div>
    </template>
    </transition>
    <!-- End catalog modal -->

    <!-- Add stock modal -->
    <transition name="fade-dental">
      <template v-if="showAddStockModal">
        <div class="dental-modal-wrapper">
          <div @click="showAddStockModal = false" class="dental-modal-fade"></div>
          <div class="add-stock-modal dental-modal container-fluid">

            <div class="utlz-row">
              <div class="dental-modal-header utlz-col-12">
                <h1 v-translation="{ type: 'title', code: 'title_add_stock_title'}"></h1>
              </div>

              <div class="utlz-col-12 dental-modal-body">
                <div class="utlz-row form-line align-items-center current-stock">
                  <div class="utlz-col-8 utlz-col-sm-9">
                    <span v-translation="{ type: 'label', code: 'lbl_stock_quantities_stock'}"></span>
                  </div>
                  <div class="utlz-col-4 utlz-col-sm-3">
                    <input
                      v-model="formFields.currentStock" type="number" min="0"
                      :class="{'invalid': formFields.currentStock < 0 || formFields.currentStock === '' }">
                  </div>
                </div>

                <div class="utlz-row form-line align-items-center in-nota">
                  <div class="utlz-col-8 utlz-col-sm-9">
                    <span v-translation="{ type: 'label', code: 'lbl_stock_quantities_nota'}"></span>
                  </div>
                  <div class="utlz-col-4 utlz-col-sm-3">
                    <input
                      v-model="formFields.notaStock" type="number" min="0"
                      :class="{'invalid': formFields.notaStock < 0 || formFields.notaStock === '' }">
                  </div>
                </div>

                <div class="utlz-row form-line align-items-center min-stock">
                  <div class="utlz-col-8 utlz-col-sm-9">
                    <span v-translation="{ type: 'label', code: 'lbl_stock_quantities_min_stock'}"></span>
                  </div>
                  <div class="utlz-col-4 utlz-col-sm-3">
                    <input
                      v-model="formFields.minStock" type="number" min="0"
                      :class="{'invalid': formFields.minStock < 0 || formFields.minStock === '' }">
                  </div>
                </div>

                <div class="utlz-row form-line align-items-center max-stock">
                  <div class="utlz-col-8 utlz-col-sm-9">
                    <span v-translation="{ type: 'label', code: 'lbl_stock_quantities_max_stock'}"></span>
                  </div>
                  <div class="utlz-col-4 utlz-col-sm-3">
                    <input
                      v-model="formFields.maxStock" type="number" min="0"
                      :class="{'invalid': formFields.maxStock < 0 || formFields.maxStock === '' }">
                  </div>
                </div>

              </div>
              <div class="utlz-col-12 dental-modal-footer">
                <div class="prompt">
                  <a
                    @click="showAddStockModal = false"
                    v-translation="{ type: 'button', code: 'button_dentalair_cancel_product' }"
                    class="btn-u cancel">
                  </a>
                  <a :class="{'disabled': !formValid}"
                    @click="addToStock()"
                    v-translation="{ type: 'button', code: 'button_dentalair_remove_product' }"
                    class="btn-u confirm">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </transition>
    <!-- End add stock modal -->

    <!-- Remove stock modal -->
    <transition name="fade-dental">
      <template v-if="showRemoveStockModal">
        <div class="dental-modal-wrapper">
          <div @click="showAddStockModal = false" class="dental-modal-fade"></div>
          <div class="remove-stock-modal dental-modal container-fluid">

            <div class="utlz-row">
              <div class="dental-modal-header utlz-col-12">
                <h1 v-translation="{ type: 'title', code: 'label_remove_stock_title'}"></h1>
              </div>

              <div class="utlz-col-12 dental-modal-body">
                <div class="utlz-row current-stock">
                  <div class="utlz-col-12">
                    <div v-translation="{ type: 'text', code: 'text_remove_stock'}"></div>
                  </div>
                </div>
              </div>

              <div class="utlz-col-12 dental-modal-footer">
                <div class="prompt">
                  <a
                    @click="showRemoveStockModal = false"
                    v-translation="{ type: 'button', code: 'button_dentalair_cancel_product' }"
                    class="btn-u cancel">
                  </a>
                  <a
                    @click="removeFromStock()"
                    v-translation="{ type: 'button', code: 'button_dentalair_remove_product' }"
                    class="btn-u confirm">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </transition>
    <!-- End remove stock modal -->

  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import { Product } from 'src/models/product.model';
export default {
  props: {
    product: { type: Product, required: true },
    usingProductDetails: { type: Boolean, required: false, default: false }
  },
  data () {
    return {
      hover: false,
      baseUrl: '/Bespoke/WebMethods/DentalWebMethods.aspx/',
      showCatalogModal: false,
      showAddStockModal: false,
      showRemoveStockModal: false,
      formFields: {
        currentStock: 0,
        notaStock: 0,
        minStock: 0,
        maxStock: 0
      }
    };
  },
  computed: {
    ...mapGetters(['bespokeGlobalWs', 'screenWidth']),
    showMenu() {
      if (this.screenWidth < 992) {
        return this.hover === true;
      } else {
        return true;
      }
    },
    productInCatalog () {
      return this.product.includedInCustomerLists.includes('praktijkCatalogus');
    },
    productInCustomerStock () {
      return this.product.includedInCustomerLists.includes('customerStock');
    },
    addToCatalogEndpoint () {
      return this.baseUrl + 'AddToCustomerCatalogue';
    },
    removeFromCatalogEndpoint () {
      return this.baseUrl + 'RemoveCustomerProduct';
    },
    addToStockEndpoint () {
      return this.baseUrl + 'AddToCustomerStock';
    },
    removeFromStockEndpoint () {
      return this.baseUrl + 'RemoveCustomerStock';
    },
    formValid () {
      let valid = true;
      Object.keys(this.formFields).forEach(key => {
        if (this.formFields[key] < 0 || this.formFields[key] === '') {
          valid = false;
        }
      });
      return valid;
    }
  },
  methods: {
    ...mapActions('elastic', ['addProductToCustomerList', 'removeProductFromCustomerList']),
    ...mapActions('productDetails', ['addProductToCustomerListProductDetails']),
    printBarcode () {
      window.openWindow(`/Bespoke/BarcodeGenerator.aspx?prod_code=${this.product.id}&type=product'`, 800, 700, true);
    },
    toggleCatalog () {
      if (!this.productInCatalog) {
        this.addToCatalog();
      } else {
        window.openWindow(`/Bespoke/request_product.aspx?prod_code=${this.product.id}&remove=true&manager=true&RefreshId="${''}", 800, 700); return false;`);
      }
    },
    openAddToStockModal () {
      window.openWindow(`/Bespoke/AddStock.aspx?prod_code=${this.product.id}&RefreshId=${''}, 800, 350); return false;`);
    },
    openRemoveFromStockModal () {
      window.openWindow(`/Bespoke/RemoveStock.aspx?prod_code=${this.product.id}&RefreshId=${''}, 800, 200); return false;`);
    },
    addToCatalog () {
      // orderType 1 = Order everything
      // orderType 2 = Order only from catalog, meaning open fiat modal
      // orderType 3 = Order only from stock/recommendations
      if (!this.bespokeGlobalWs.userIsManager && this.bespokeGlobalWs.orderType === 2) {
        window.openWindow(`/Bespoke/request_product.aspx?prod_code=${this.product.id}&RefreshId=button_refresh`, 800, 700);
      } else {
        // Update local state of product in the elastic store
        if (!this.usingProductDetails) {
          axios.post(this.addToCatalogEndpoint, { productCode: this.product.id }).then(
            res => {
              this.addProductToCustomerList({ productCode: this.product.id, listId: 'praktijkCatalogus' });
              window.updateOrderMessage('Product added to catalog');
            }
          );
        } else {
          // Update local customerlist in product details store when this control is loaded in the product details
          axios.post(this.addToCatalogEndpoint, { productCode: this.product.id }).then(
            res => {
              this.addProductToCustomerListProductDetails({ productCode: this.product.id, listId: 'praktijkCatalogus' });
              window.updateOrderMessage('Product added to catalog');
            }
          );
        }
        
      }
    },
    addToStock () {
      const payload = {
        productCode: this.product.id,
        currentStock: parseInt(this.formFields.currentStock),
        notaStock: parseInt(this.formFields.notaStock),
        minStock: parseInt(this.formFields.minStock),
        maxStock: parseInt(this.formFields.maxStock)
      };
      axios.post(this.addToStockEndpoint, payload)
        .then(
          res => {
            this.showAddStockModal = false;
            this.addProductToCustomerList({ productCode: this.product.id, listId: 'customerStock' });
          }
        )
    },
    removeFromStock () {
      axios.post(this.removeFromStockEndpoint, { productCode: this.product.id }).then(
        res => {
          this.showRemoveStockModal = false;
          this.removeProductFromCustomerList({ productCode: this.product.id, listId: 'customerStock' });
        }
      );
    }
  }
}
</script>

<style>

</style>
