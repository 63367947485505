<template>
  <div class="cmp-elastic-product-details utlz-reset utlz-row" v-if="alternativeProduct !== null">
    
      <div class="utlz-col-12 alt-prod-title">
        <h2 v-translation="{ code: 'title_alternative_product', type: 'title' }"></h2>
      </div>

      <div class="utlz-col-md-4 column-left">
        <div class="icons-container">
          <img v-if="alternativeProduct.customBooleans.IS_BEST_BUY" :src="bespokeGlobalWs.bestBuyImgUrl" class="best-buy-logo product-icon" />          
          <img v-if="alternativeProduct.customBooleans.IS_OUTLET_PRODUCT" src="/Bespoke/Images/Outlet/icoon-outlet.png" class="outlet-logo product-icon">          
          <img v-if="!alternativeProduct.customBooleans.IS_BEST_BUY && alternativeProduct.customStrings.BEST_BUY_PROD_CODE" :src="bespokeGlobalWs.bestBuyAvailIcon" class="best-buy-avail-logo product-icon">          
        </div>
        <div class="icons-container icons-right">
          <!-- BESPOKE DEN: Added green buy icon -->
          <img v-if="alternativeProduct.customBooleans.IS_GREEN_BUY" :src="bespokeGlobalWs.greenBuyIcon" class="green-buy-logo product-icon">            
          <!-- END BESPOKE DEN -->
        </div>

        <utlz-gallery :product="alternativeProduct"></utlz-gallery>
      </div>
      <div class="utlz-col-md-8 column-right product-details-column">
          <div class="product-information">
            <h1>{{alternativeProduct.shortDescription}}</h1>
            <div class="product-code">
                <span>{{alternativeProduct.id}}</span>
            </div>
            <div class="ph-product-price" v-if="alternativeProduct.prices.length">
                <utlz-product-price                  
                  :product="alternativeProduct"
                  :prices="alternativeProduct.prices[0]"
                ></utlz-product-price>
            </div>
            
            <div class="prod-desc-container" :class="{'expandable': useCollapse, 'expanded': expanded  }">
              <div ref="productDescription" class="prod-desc-wrapper">
                <div class="product-long-description" v-html="alternativeProduct.longDescription">
                </div>
              </div>
            </div>
            <a v-if="useCollapse" @click="expanded = !expanded" class="collapse-button theme-primary">
              <div v-if="!expanded" class="read-more">
                <span v-translation="{ type: 'button', code: 'link_description_read_more' }"></span>
                <i class="fa fa-angle-down" aria-hidden="true"></i>
              </div>
              <div v-if="expanded" class="read-less">
                <span v-translation="{ type: 'button', code: 'link_description_read_less' }"></span>
                <i class="fa fa-angle-up" aria-hidden="true"></i>
              </div>
            </a>

            <div class="product-specifications">
              <h2 v-translation="{ type: 'title', code: 'title_specifications' }"></h2>
              <div class="spec-list">
                <div class="spec-item" v-for="prop in productProperties" :key="prop.key">
                  <div class="spec-desc">
                    {{prop.key}}
                  </div>
                  <div class="spec-value">
                    {{prop.value}}
                  </div>
                </div>
              </div>
            </div>

            <div class="order-product">
              <!-- BESPOKE DEN-458: Added v-if and v-else templates to show order product or request price button -->
              <template v-if="!alternativeProduct.customBooleans.PRICE_ON_REQUEST || bespokeGlobalWs.ignorePriceOnRequest">
                <utlz-order-product
                  v-if="showOrderProductControl && alternativeProduct.prices.length"
                  :product="alternativeProduct"
                  :useOrderComment="useOrderLineComments"
                  >
                </utlz-order-product>                
              </template>
              <template v-else>
                <a @click="requestPriceEmail()" v-if="showOrderProduct" class="request-price-btn" v-translation="{ type: 'label', code: 'label_price_on_request' }"></a>
              </template>
              <!-- END BESPOKE DEN-458 -->

                <transition name="fade">
                  <p v-if="!showOrderProductControl && showStock" class="out-of-stock-msg">
                    <i class="fa fa-warning"></i>
                    <span v-translation="{ type: 'label', code: 'label_out_of_stock' }"></span>
                  </p>
                </transition>
            </div>

            <!-- BESPOKE -->
            <utlz-product-options
              v-if="showOrderProductControl"
              :product="alternativeProduct"
            ></utlz-product-options>
            <!-- END BESPOKE -->

            <!-- BESPOKE DEN-329 -->
          <utlz-stock-quantities v-if="alternativeProduct.includedInCustomerLists.includes('customerStock')" :product="alternativeProduct"></utlz-stock-quantities>
          <!-- END BESPOKE DEN-329 -->
          </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState, mapGetters } from 'vuex';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import OrderProduct from 'src/components/webshop/order-product/OrderProduct.vue';
import Gallery from 'src/components/ui/gallery/Gallery.vue';
import ProductOptions from './../product-options/ProductOptions.vue';
import StockQuantities from './../stock-quantities/StockQuantities.vue';
// END BESPOKE DEN-293
export default {
  components: {
    'utlz-product-price': ProductPrice,
    'utlz-order-product': OrderProduct,
    'utlz-gallery': Gallery,
    'utlz-product-options': ProductOptions,
    'utlz-stock-quantities': StockQuantities
  },
  data () {
    return {
      expanded: false,
      productDescriptionHeight: 0
    }
  },
  computed: {
    ...mapState('productDetails', ['alternativeProduct']),
    ...mapGetters(['showStock', 'showPrices', 'showFavorites', 'showOrderProduct', 'stockLimit', 'useOrderLineComments', 'relatedProductBlockSize', 'bespokeGlobalWs']),    
    useCollapse () {
      return this.productDescriptionHeight > 150;
    },
    showOrderProductControl () {
      if (!this.showPrices) {
        return false;
      } else if (this.showOrderProduct) {
        if (!this.stockLimit) {
          return true;
        } else if (this.stockLimit && this.alternativeProduct.stock !== null) {
          return this.stockLimit && this.alternativeProduct.stock.stockTotal > 0;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    productProperties () {
      return Object.keys(this.alternativeProduct.properties).map(x => {
        return { key: x, value: this.alternativeProduct.properties[x] }
      });
    },
    images () {
      let images = [];

      images.push(this.alternativeProduct.imageUrl)

      this.alternativeProduct.images.forEach(element => {
        images.push(element);
      });

      return images
    }
  },
  updated () {
    if (this.$refs.productDescription !== undefined) {
      this.productDescriptionHeight = this.$refs.productDescription.clientHeight;
    }
  },
  methods: {
    requestPriceEmail () {
      axios.post('/Bespoke/WebMethods/DentalWebMethods.aspx/PriceOnRequest', { productCode: this.alternativeProduct.id }).then(
        res => {
          window.updateOrderMessage(window.vue.translations.label.message_email_price_request_sent);
        }
      );
    }
  }
}
</script>

<style>

</style>
