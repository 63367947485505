<template>
  <div class="cmp-elastic-product-details utlz-reset utlz-row">

      <!-- BESPOKE DEN -->
      <div class="back-btn col-md-12">
        <i class="fa fa-angle-left" aria-hidden="true"></i>
        <a @click="returnToOverview()" v-translation="{ type: 'button', code: 'button_back_overview' }"></a>
      </div>
      <!-- END BESPOKE DEN -->

      <div class="utlz-col-md-4 column-left">
        <!-- BESPOKE DEN-557 -->
        <template v-if="prodNotAvailable">
          <div class="availability-icons">
            <span v-if="productDetails.customBooleans.DEFINITE_UNAVAILABLE" class="not-avail" v-translation="{ code: 'label_not_avail', type: 'label'}"></span>
            <span v-if="productDetails.customBooleans.TEMP_UNAVAILABLE" class="temp-not-avail" v-translation="{ code: 'label_temp_not_avail', type: 'label'}"></span>
          </div>
        </template>
        <template v-else>
          <div class="icons-container">
            <img v-if="productDetails.customBooleans.IS_BEST_BUY" :src="bespokeGlobalWs.bestBuyImgUrl" class="best-buy-logo product-icon" />          
            <img v-if="productDetails.customBooleans.IS_OUTLET_PRODUCT" src="/Bespoke/Images/Outlet/icoon-outlet.png" class="outlet-logo product-icon">          
            <img v-if="!productDetails.customBooleans.IS_BEST_BUY && productDetails.customStrings.BEST_BUY_PROD_CODE" :src="bespokeGlobalWs.bestBuyAvailIcon" class="best-buy-avail-logo product-icon">          
          </div>    
        </template>    
        <!-- END BESPOKE DEN-557 -->

        <div class="icons-container icons-right">
            <img v-if="productDetails.customBooleans.IS_GREEN_BUY" :src="bespokeGlobalWs.greenBuyIcon" class="green-buy-logo product-icon">            
          </div>
        <!-- END BESPOKE -->

        <utlz-gallery :product="productDetails"></utlz-gallery>
      </div>
      <div class="utlz-col-md-8 column-right product-details-column">
          <div class="product-information">
            <h1>{{productDetails.shortDescription}}</h1>
            <div class="product-code">
                <span>{{productDetails.id}}</span>
            </div>

            <!-- BESPOKE DEN-557: Do not show price if product is not available -->
            <template v-if="!prodNotAvailable">
              <div class="ph-product-price" v-if="productDetails.prices.length">
                <!-- BESPOKE DEN: Added :product="productDetails" -->
                <utlz-product-price                  
                  :product="productDetails"
                  :prices="productDetails.prices[0]"
                ></utlz-product-price>
                <!-- END BESPOKE DEN-557 -->
            </div>

            </template>
            
            <!-- BESPOKE DEN: Moved prod desc out of tabs -->
            <div class="prod-desc-container" :class="{'expandable': useCollapse, 'expanded': expanded  }">
              <div ref="productDescription" class="prod-desc-wrapper">
                <div class="product-long-description" v-html="productDetails.longDescription">
                </div>
              </div>
            </div>
            <a v-if="useCollapse" @click="expanded = !expanded" class="collapse-button theme-primary">
              <div v-if="!expanded" class="read-more">
                <span v-translation="{ type: 'button', code: 'link_description_read_more' }"></span>
                <i class="fa fa-angle-down" aria-hidden="true"></i>
              </div>
              <div v-if="expanded" class="read-less">
                <span v-translation="{ type: 'button', code: 'link_description_read_less' }"></span>
                <i class="fa fa-angle-up" aria-hidden="true"></i>
              </div>
            </a>
            <!-- END BESPOKE DEN -->

            <utlz-tabs :tabs="tabs">

              <template v-slot:description>
                
              </template>

              <template v-slot:videos>
                <div class="product-videos" v-if="videos.length">
                  <utlz-product-videos :videos="videos"></utlz-product-videos>
                </div>
              </template>

              <template v-slot:documents v-if="documents.length">
                <div class="product-documents">
                  <utlz-product-documents :documents="documents"></utlz-product-documents>
                </div>
              </template>
            </utlz-tabs>

            <div class="product-specifications">
              <h2 v-translation="{ type: 'title', code: 'title_specifications' }"></h2>
              <div class="spec-list">
                <div class="spec-item" v-for="prop in productProperties" :key="prop.key">
                  <div class="spec-desc">
                    {{prop.key}}
                  </div>
                  <div class="spec-value">
                    {{prop.value}}
                  </div>
                </div>
              </div>
            </div>

            <utlz-group-dropdown :variants="productVariants" />

            <!-- BESPOKE DEN: Don't show product stock -->
            <!-- <utlz-product-stock
              :product="productDetails"
              :stock="productDetails.stock"
              :stockTotal="productDetails.stock"
            ></utlz-product-stock> -->
            <!-- END BESPOKE DEN -->

            <!-- BESPOKE DEN-557 -->
            <template v-if="!prodNotAvailable">
            <div class="order-product">
              <!-- BESPOKE DEN-458: Added v-if and v-else templates to show order product or request price button -->
              <template v-if="!productDetails.customBooleans.PRICE_ON_REQUEST || bespokeGlobalWs.ignorePriceOnRequest">
                <utlz-order-product
                  v-if="showOrderProductControl"
                  :product="productDetails"
                  :useOrderComment="useOrderLineComments"
                  >
                </utlz-order-product>                
              </template>
              <template v-else>
                <a @click="requestPriceEmail()" v-if="showOrderProduct" class="request-price-btn" v-translation="{ type: 'label', code: 'label_price_on_request' }"></a>
              </template>
              <!-- END BESPOKE DEN-458 -->

                <transition name="fade">
                  <p v-if="!showOrderProductControl && showStock" class="out-of-stock-msg">
                    <i class="fa fa-warning"></i>
                    <span v-translation="{ type: 'label', code: 'label_out_of_stock' }"></span>
                  </p>
                </transition>
            </div>
            </template>

            <div class="favorite">
              <utlz-favorites-control v-if="showFavorites" :product="productDetails" :useDescription="true"></utlz-favorites-control>
            </div>

            <!-- BESPOKE DEN-557 -->
            <template v-if="!prodNotAvailable">
              <utlz-product-options
                v-if="showOrderProductControl"
                :product="productDetails"
                :usingProductDetails="true"
              ></utlz-product-options>
            </template>
            <!-- END BESPOKE DEN-557 -->

            <!-- BESPOKE DEN-329 -->
          <template v-if="!prodNotAvailable">
            <utlz-stock-quantities v-if="productDetails.includedInCustomerLists.includes('customerStock')" :onDetailPage="true" :product="productDetails"></utlz-stock-quantities>
          </template>
          <!-- END BESPOKE DEN-329 -->
          </div>
      </div>

      <div class="utlz-col-12 margin-bottom-30"></div>

      <div v-if="showOrderProductControl && productDetails.prices.length && productVariants.length" class="utlz-col-md-12">
        <utlz-group-matrix :variants="productVariants" />
      </div>

      <!-- BESPOKE DEN-457 -->  
      <div class="utlz-col-12">
        <utlz-alternative-product v-if="alternativeProduct"></utlz-alternative-product>
      </div>
      <!-- END BESPOKE DEN-457 -->

      <div v-if="relatedProducts.upSelling.length" class="related-products upselling utlz-col-12 ">
        <utlz-product-carousel
          :products="relatedProducts.upSelling"
          :blockSize="relatedProductBlockSize"
          :title="titleUpSelling"
        ></utlz-product-carousel>
      </div>

      <div v-if="relatedProducts.crossSelling.length" class="related-products upselling utlz-col-12 ">
        <utlz-product-carousel
          :products="relatedProducts.crossSelling"
          :blockSize="relatedProductBlockSize"
          :title="titleCrossSelling"
        ></utlz-product-carousel>
      </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import OrderProduct from 'src/components/webshop/order-product/OrderProduct.vue';
import ProductStock from 'src/components/webshop/product-stock/ProductStock.vue';
import ProductGroupMatrix from 'src/components/webshop/products/product-group/ProductGroupMatrix.vue';
import ProductGroupDropdown from 'src/components/webshop/products/product-group/ProductGroupDropdowns.vue';
import FavoritesControl from 'src/components/webshop/favorites/FavoritesControl.vue';
import Gallery from 'src/components/ui/gallery/Gallery.vue';
import Tabs from 'src/components/ui/tabs/Tabs.vue';
import ProductVideos from 'src/components/webshop/products/product-videos/ProductVideos.vue';
import ProductDocuments from 'src/components/webshop/products/product-documents/ProductDocuments.vue';
import ProductCarousel from 'src/components/webshop/products/product-carousel/ProductCarousel.vue';
// BESPOKE DEN-293
import axios from 'axios';
import ProductOptions from './../../../../bespoke-components/product-options/ProductOptions.vue';
import StockQuantities from './../../../../bespoke-components/stock-quantities/StockQuantities.vue';
import AlternativeProduct from './../../../../bespoke-components/alternative-product/AlternativeProduct.vue';
// END BESPOKE DEN-293
export default {
  components: {
    'utlz-product-price': ProductPrice,
    'utlz-order-product': OrderProduct,
    'utlz-product-stock': ProductStock,
    'utlz-group-matrix': ProductGroupMatrix,
    'utlz-group-dropdown': ProductGroupDropdown,
    'utlz-favorites-control': FavoritesControl,
    'utlz-gallery': Gallery,
    'utlz-tabs': Tabs,
    'utlz-product-carousel': ProductCarousel,
    'utlz-product-videos': ProductVideos,
    'utlz-product-documents': ProductDocuments,
    // BESPOKE
    'utlz-product-options': ProductOptions,
    'utlz-stock-quantities': StockQuantities,
    'utlz-alternative-product': AlternativeProduct
    // END BESPOKE
  },
  data () {
    return {
      expanded: false,
      productDescriptionHeight: 0,
      titleUpSelling: window.vue.translations.title.title_product_relations,
      titleCrossSelling: window.vue.translations.title.title_product_relations_cross,
      tabs: [
        { id: 'description', description: window.vue.translations.label.label_tab_product_desc, active: true }
      ],
      // BESPOKE DEN-557
      prodNotAvailable: false
      // END BESPOKE DEN-557
    }
  },
  watch: {
    productDetails(val) {
      if (val.customBooleans != undefined) {
        this.prodNotAvailable = this.productDetails.customBooleans.TEMP_UNAVAILABLE === true || this.productDetails.customBooleans.DEFINITE_UNAVAILABLE === true
      }
    },
    documents (val) {
      if (val.length) {
        this.tabs.push({ id: 'documents', description: window.vue.translations.label.label_tab_product_documents, active: false });
      } else {
        this.tabs.splice(this.tabs.findIndex(tab => tab.id === 'documents'), 1);
      }
    },
    videos (val) {
      if (val.length) {
        this.tabs.push({ id: 'videos', description: window.vue.translations.label.label_tab_product_videos, active: false });
      } else {
        this.tabs.splice(this.tabs.findIndex(tab => tab.id === 'videos'), 1);
      }
    }
  },
  computed: {
    ...mapState('productDetails', ['productDetails', 'productVariants', 'relatedProducts', 'videos', 'alternativeProduct']),
    // BESPOKE DEN
    // Added bespokeGlobalWs to mapGetters
    ...mapGetters(['showStock', 'showPrices', 'showFavorites', 'showOrderProduct', 'stockLimit', 'useOrderLineComments', 'relatedProductBlockSize', 'bespokeGlobalWs']),
    // END BESPOKE DEN
    ...mapGetters('productDetails', ['documents']),
    useCollapse () {
      // BESPOKE DEN
      // Changed height to 100, this should have been fixed in a future release
      return this.productDescriptionHeight > 100;
      // END BESPOKE DEN
    },
    showOrderProductControl () {
      if (this.showOrderProduct) {
        if (!this.stockLimit) {
          return true;
        } else if (this.stockLimit && this.productDetails.stock !== null) {
          return this.stockLimit && this.productDetails.stock.stockTotal > 0;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    productProperties () {
      return Object.keys(this.productDetails.properties).map(x => {
        return { key: x, value: this.productDetails.properties[x] }
      });
    },
    images () {
      let images = [];

      images.push(this.productDetails.imageUrl)

      this.productDetails.images.forEach(element => {
        images.push(element);
      });

      return images
    }
  },
  updated () {
    if (this.$refs.productDescription !== undefined) {
      this.productDescriptionHeight = this.$refs.productDescription.clientHeight;
    }
  },
  created () {
    this.initDetails();
  },
  methods: {
    ...mapActions('productDetails', ['initDetails']),
    // BESPOKE DEN-458
    returnToOverview() {
      history.back();
    },
    requestPriceEmail () {
      axios.post('/Bespoke/WebMethods/DentalWebMethods.aspx/PriceOnRequest', { productCode: this.productDetails.id }).then(
        res => {
          window.updateOrderMessage(window.vue.translations.label.message_email_price_request_sent);
        }
      );
    }
    // END BESPOKE DEN-458
  }
}
</script>

<style>

</style>
