<template>
  <div class="cmp-sorting-options utlz-row normalize-text mb-sm-3" :class="parentClassRef">
    <div class="utlz-col-md-12">
      <div class="sort-options-wrapper flex-row-wrap justify-content-between align-items-center">

        <!-- BESPOKE DEN: Moved slot up and removed .hidden-md -->
        <div class="cmp-slot pagination-slot">
          <slot name="pagination"></slot>
        </div>
        <!-- END BESPOKE DEN -->

        <!-- BESPOKE DEN: Disable product counter -->
        <!-- <p class="product-counter" v-translation="{ type: 'label', code: 'label_showing_result', interpolations: [pageRangeString, totalProducts] }"></p> -->
        <!-- END BESPOKE DEN -->

        <!-- BESPOKE DEN: Added div.page-sizes and wrapped select in it -->
        <div class="page-sizes">
          <span v-translation="{ type: 'label', code: 'label_items_per_page1'}" class="page-size-label"></span>
          <select v-model="selected" @change="changePageSize()" class="small-tablet">
            <option v-for="page in pageSize" :key="page">{{page}}</option>
          </select>
        </div>
        <!-- END BESPOKE DEN -->

        <div class="select-sorting" v-if="pageSorting.length > 1">
          <span class="select-label" v-translation="{ type: 'label', code: 'label_sorting'}"></span>
          <select v-model="sorting" @change="changePageSorting()" class="small-tablet">
            <option v-for="sort in pageSorting" :key="sort.Description" :value="sort">{{sort.Description}}</option>
          </select>
        </div>

        <div class="cmp-slot toggle-filters-slot hidden-lg">
          <slot name="toggle-mobile-filters"></slot>
        </div>

        <ul class="grid-view-btns hidden-md flex-row-nowrap just-space-between">
          <li class="blocks">
            <a @click="changeLayout('blocks');" class="button square" :class="{ active: layoutType === 'blocks', 'theme-primary': layoutType === 'blocks' }">
              <i class="fa fa-th"></i>
            </a>
          </li>
          <li class="list">
            <a @click="changeLayout('rows');" class="button square" :class="{ active: layoutType === 'rows', 'theme-primary': layoutType === 'rows'}">
              <i class="fa fa-list"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    layoutType: { type: String, required: false, default: 'blocks' },
    totalProducts: { type: Number, required: true },
    pageSize: { type: Array, required: false, default: () => [32, 64, 128] },
    pageSorting: { type: Array, required: false, default: () => [{ 'Description': 'default', 'Field': '', 'Type': 0 }] },
    pageRange: { type: Object, required: true },
    parentClassRef: { type: String, default: '', required: false }
  },
  data () {
    return {
      selected: this.pageSize[0],
      sorting: this.pageSorting[0]
    }
  },
  computed: {
    pageRangeString () {
      return this.pageRange.from + ' - ' + this.pageRange.to;
    }
  },
  methods: {
    changeLayout (layoutType) {
      this.$emit('changeLayout', layoutType);
    },
    changePageSize () {
      this.$emit('changePageSize', parseInt(this.selected));
    },
    changePageSorting () {
      this.$emit('changePageSorting', this.sorting);
    }
  }
}
</script>

<style>

</style>
