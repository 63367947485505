<template>
  <div class="cmp-product-list-item utlz-col-sm-4" :class="'utlz-col-lg-' + blockSize">
    <!-- BESPOKE DEN: Add class .hover-wrapper if user is logged in so we can style the hover -->
    <div class="utlz-row utlz-no-gutters utlz-gutters-sm item-wrapper" :class="{ 'hover-container': showOrderProduct }">
      
      <span v-if="isSalesAction" v-translation="{ type: 'label', code: 'label_sales_action' }" class="action-banner"></span>
      
      <div class="utlz-col-3 utlz-col-sm-12 img-container">

          <utlz-favorites-control v-if="showFavorites" :product="product"></utlz-favorites-control>

          <!-- BESPOKE DEN-557 -->
          <template v-if="prodNotAvailable && screenWidth >= 576">
            <div class="availability-icons">
              <span v-if="product.customBooleans.DEFINITE_UNAVAILABLE" class="not-avail" v-translation="{ code: 'label_not_avail', type: 'label'}"></span>
              <span v-if="product.customBooleans.TEMP_UNAVAILABLE" class="temp-not-avail" v-translation="{ code: 'label_temp_not_avail', type: 'label'}"></span>
            </div>
          </template>
          <!-- END BESPOKE DEN-557 -->
        
          <template v-if="!prodNotAvailable">
            <div class="icons-container">
              <!-- BESPOKE: Added best buy logo -->
              <img v-if="product.customBooleans.IS_BEST_BUY" :src="bespokeGlobalWs.bestBuyImgUrl" class="best-buy-logo product-icon" />
              <!-- END BESPOKE -->
              <!-- BESPOKE: Added outlet logo -->
              <img v-if="product.customBooleans.IS_OUTLET_PRODUCT" src="/Bespoke/Images/Outlet/icoon-outlet.png" class="outlet-logo product-icon">
              <!-- END BESPOKE -->
              <!-- BESPOKE: Added best buy avail logo -->
              <img v-if="!product.customBooleans.IS_BEST_BUY && product.customStrings.BEST_BUY_PROD_CODE" :src="bespokeGlobalWs.bestBuyAvailIcon" class="best-buy-avail-logo product-icon">
              <!-- END BESPOKE -->            
            </div>

            <div class="icons-container icons-right">
              <!-- BESPOKE: Added best buy avail logo -->
              <img v-if="product.customBooleans.IS_GREEN_BUY" :src="bespokeGlobalWs.greenBuyIcon" class="green-buy-logo product-icon">
              <!-- END BESPOKE -->
            </div>
          </template>

        <!-- BESPOKE DEN-293: Added product options, but do not show if product is not available -->
        <template v-if="!prodNotAvailable">
          <utlz-product-options
            v-if="showOrderProduct && screenWidth > 575"
            :product="product"
          ></utlz-product-options>
        </template>
        <!-- END BESPOKE DEN-293 -->

          <template
            v-if="product.productImageBanners.length && screenWidth >= 576">
            <div class="product-banners">
              <div v-for="(banner, index) in productImageBanners" :key="'prod-banner-wrapper-' + index" :class="{ [banner.position]: true }" class="prod-banner-wrapper">
                <div v-for="(bannerVal, ind) in banner.values" :key="'prod-banner-wrapper-' + ind">
                  <span class="text-banner" v-if="bannerVal.Type === 0" :style="'background-color:' + bannerVal.Color + ';color: ' + bannerVal.TextColor">
                    {{ bannerVal.Text }}
                  </span>
                  <img class="img-banner" v-if="bannerVal.Type === 1" :src="'/' + bannerVal.Image" />
                </div>
              </div>
            </div>
          </template>

          <figure class="img-square prod-img">
            <a :href="product.targetUrl" class="clickthrough">
              <!-- BESPOKE PI: Added :srcset to image so we can use cloudinary images as a srcset -->
              <img v-if="screenWidth < 576" class="img-fluid" :alt="product.imageAltText" :title="product.imageTitleText" :src="product.imageUrl ? product.imageUrlSmall : '/documents/productimages/not-available-large.jpg'" />
              <img v-if="screenWidth >= 576" class="img-fluid" :alt="product.imageAltText" :title="product.imageTitleText" :src="product.imageUrl ? product.imageUrlMedium : '/documents/productimages/not-available-large.jpg'" />
              <!-- END BESPOKE KOB -->
            </a>
          </figure>
      </div>

      <div class="utlz-col-9 utlz-col-sm-12 text-container">
        <a :href="product.targetUrl" class="clickthrough">  
          <template v-if="product.productImageBanners.length && screenWidth <= 576">
            <div class="product-banners">
              <div v-for="(banner, index) in productImageBanners" :key="'prod-banner-wrapper-' + index" :class="{ [banner.position]: true }" class="prod-banner-wrapper">
                <div v-for="(bannerVal, ind) in banner.values" :key="'prod-banner-wrapper-' + ind">
                  <span class="text-banner" v-if="bannerVal.Type === 0" :style="'background-color:' + bannerVal.Color + ';color: ' + bannerVal.TextColor">
                    {{ bannerVal.Text }}
                  </span>
                  <img class="img-banner" v-if="bannerVal.Type === 1" :src="'/' + bannerVal.Image" />
                </div>
              </div>
            </div>
          </template>

          <!-- BESPOKE DEN-557 -->
          <template v-if="prodNotAvailable && screenWidth <= 576">
            <div class="availability-icons">
              <span v-if="product.customBooleans.DEFINITE_UNAVAILABLE" class="not-avail" v-translation="{ code: 'label_not_avail', type: 'label'}"></span>
              <span v-if="product.customBooleans.TEMP_UNAVAILABLE" class="temp-not-avail" v-translation="{ code: 'label_temp_not_avail', type: 'label'}"></span>
            </div>
          </template>
          <!-- END BESPOKE DEN-557 -->

          <h2 class="prod-title header-small header-normal-sm">{{ product.shortDescription }}</h2>
          <!-- BESPOKE: Moved div.prod-code below title and added translation for the prod code label -->
          <div class="product-code">
              <span v-translation="{ type: 'label', code: 'label_product_code' }"></span>
              <span class="value">{{ product.id }}</span>
          </div>
          <!-- END BESPOKE -->
        </a>

        <!-- BESPOKE: Added product brand -->
        <div class="product-brand">
            <span class="value">{{ product.properties.Merk }}</span>
        </div>
        <!-- END BESPOKE -->

        <!-- BESPOKE DEN: Do not show if product is not available -->
        <template v-if="!prodNotAvailable">
          <div class="ph-product-price" v-if="product.prices.length">
            <!-- BESPOKE DEN: Added :product="product" -->
            <utlz-product-price 
              :product="product"
              :prices="product.prices[0]"
              :parentClassRef="'ref-product-list-item'">
            </utlz-product-price>
            <!-- END BESPOKE DEN -->
          </div>
        </template>

        <utlz-product-variant
          v-if="product.variantKey && groupProductsList != 0 && showOrderProductControl"
          :product="computedProduct"
          :productVariants="product.productVariants"
          @selectedProduct="selectedProduct($event)"
        ></utlz-product-variant>

        <div class="hover-wrapper">
          <!-- BESPOKE DEN-293: Added product options -->
          <utlz-product-options
            v-if="showOrderProduct && screenWidth <= 574"
            :product="product"
          ></utlz-product-options>
          <!-- END BESPOKE DEN-293 -->

          <!-- BESPOKE DEN-329 -->
          <utlz-stock-quantities v-if="customerListId === 'customerStock'" :product="product"></utlz-stock-quantities>
          <!-- END BESPOKE DEN-329 -->

          <!-- BESPOKE DEN-316: Added templates with v-if to either display order product or request price button -->
          <template v-if="!prodNotAvailable">
            <template v-if="!product.customBooleans.PRICE_ON_REQUEST || bespokeGlobalWs.ignorePriceOnRequest">
              <utlz-order-product
                v-if="showOrderProductControl"
                :product="computedProduct"
                :parentClassRef="'ref-product-list-item'">
              </utlz-order-product>
            </template>
            <template v-else>
              <a @click="requestPriceEmail()" v-if="showOrderProduct" class="request-price-btn" v-translation="{ type: 'label', code: 'label_price_on_request' }"></a>
            </template>
          </template>

        </div>

        <!-- BESPOKE DEN: Disable product stock -->
        <!-- If product has variants, disable stock and
        use stock in OrderProduct.vue component which is variant specific -->
        <!-- <utlz-product-stock
          v-if="!product.variantKey || groupProductsList == 0"
          :stock="product.stock"
          :stockTotal="product.stock"
        ></utlz-product-stock> -->
        <!-- END BESPOKE DEN -->

      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { Product } from 'src/models/product.model';
import OrderProduct from 'src/components/webshop/order-product/OrderProduct.vue';
import ProductStock from 'src/components/webshop/product-stock/ProductStock.vue';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import ProductVariant from 'src/components/webshop/products/product-variant/ProductVariant.vue';
import FavoritesControl from 'src/components/webshop/favorites/FavoritesControl.vue';
// BESPOKE DEN-293
import axios from 'axios';
import ProductOptions from './../../../../bespoke-components/product-options/ProductOptions.vue';
import StockQuantities from './../../../../bespoke-components/stock-quantities/StockQuantities.vue';
// END BESPOKE DEN-293
export default {
  components: {
    'utlz-product-price': ProductPrice,
    'utlz-product-stock': ProductStock,
    'utlz-order-product': OrderProduct,
    'utlz-product-variant': ProductVariant,
    'utlz-favorites-control': FavoritesControl,
    // BESPOKE DEN-293
    'utlz-product-options': ProductOptions,
    'utlz-stock-quantities': StockQuantities
    // END BESPOKE DEN-293
  },
  props: {
    product: { type: Product, required: true },
    blockSize: { type: Number, required: false, default: 3 }
  },
  data () {
    return {
      stockProduct: this.product.customBooleans.STOCK_PRODUCT,
      activeProductVariantId: null,
      // BESPOKE DEN-557
      prodNotAvailable: this.product.customBooleans.TEMP_UNAVAILABLE === true || this.product.customBooleans.DEFINITE_UNAVAILABLE === true
      // END BESPOKE DEN-557
    };
  },
  computed: {
    ...mapState('elastic', ['layoutType']),
    // BESPOKE DEN-290: Added bespokeGlobalWs, screenWidth
    ...mapGetters(['showStock', 'showPrices', 'showFavorites', 'showOrderProduct', 'stockLimit', 'groupProductsList', 'screenWidth', 'bespokeGlobalWs']),
    ...mapGetters('elastic', ['customerListId']),
    isSalesAction () {
      return this.product.prices.length > 0 ? this.product.prices[0].isSalesAction : false;
    },
    showOrderProductControl () {
      if (this.showOrderProduct) {
        if (!this.stockLimit || !this.stockProduct) {
          return true;
        } else if (this.stockLimit && this.product.stock !== null) {
          return this.stockLimit && this.product.stock.stockTotal > 0;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    showOutOfStockMessage () {
      if (!this.stockLimit || !this.stockProduct) {
        return false;
      } else if (this.product.stock !== null && this.showOrderProduct) {
        return this.product.stock.stockTotal <= 0;
      } else {
        return false;
      }
    },
    computedProduct () {
      if (!this.product.variantKey) {
        return this.product;
      } else {
        if (this.activeProductVariantId) {
          const index = this.product.productVariants.findIndex(product => {
            return product.id === this.activeProductVariantId;
          });
          return this.product.productVariants[index];
        } else {
          return this.product;
        }
      }
    },    
    productImageBanners () {
      return this.product.productImageBanners.filter(banner => banner.show === true);
    }
  },
  methods: {
    selectedProduct (id) {
      this.activeProductVariantId = id;
    },
    requestPriceEmail () {
      axios.post('/Bespoke/WebMethods/DentalWebMethods.aspx/PriceOnRequest', { productCode: this.product.id }).then(
        res => {
          window.updateOrderMessage(window.vue.translations.label.message_email_price_request_sent);
        }
      );
    }
  }
};
</script>

<style>
</style>
