<template>
  <div class="cmp-elastic-product-list utlz-row utlz-reset" :class="layoutType" ref="productListContainer">
    <div class="utlz-col-md-12">

      <!-- BESPOKE DEN: Added additional product counter and wrapped it with h1 -->
      <div class="productlist-header">
        <h1>{{ pageTitle }}</h1>
        <div class="prod-counter">
          <p v-if="!noProductsFound" class="product-counter" v-translation="{ type: 'label', code: 'label_showing_result', interpolations: [pageRange.from + ' - ' + pageRange.to, totalProducts] }"></p>          
          <!-- BESPOKE -->
          <div class="toggle-filters">
            <a @click="toggleMobileFilters()" class="toggle-filters-btn show-mobile">
              <i class="fa fa-filter"></i>
              <span v-translation="{ type: 'label', code: 'label_toggle_mobile_filters' }"></span>
            </a>
          </div>
        </div>
        <div v-if="noProductsFound" class="search-bar-wrapper sort-options-wrapper no-products">
          <input type="text" :placeholder="placeholderText" @keyup.enter="searchByQuery(searchText)" v-model="searchText" class="form-control" />
          <a class="inputbutton btn search-button" @click="setQueryString(searchText)"></a>
        </div>
        <!-- END BESPOKE -->
      </div>
      <!-- END BESPOKE DEN -->
        <utlz-sorting-options
          v-if="!noProductsFound"
          @changeLayout="changeLayout($event)"
          @changePageSize="changePageSize($event)"
          @changePageSorting="changePageSorting($event)"
          :pageRange="pageRange"
          :totalProducts="totalProducts"
          :layoutType="layoutType"
          :parentClassRef="'ref-elastic-product-list'"
          :pageSize="pageSizes"
          :pageSorting="sortingOptions">

          <template v-slot:pagination>
            <!-- BESPOKE DEN: added searchfield and disabled top pagination -->
            <div class="search-bar-wrapper">
              <input type="text" :placeholder="placeholderText" @keyup.enter="searchByQuery(searchText)" v-model="searchText" class="form-control" />
              <a class="inputbutton btn search-button" @click="setQueryString(searchText)"></a>
            </div>
            <!-- <utlz-pagination v-if="totalPages > 1" :totalPages="totalPages" :currentPage="currentPage" :scrollToTop="true" @changePage="changePage($event)"></utlz-pagination> -->
            <!-- END BESPOKE -->
          </template>

        <!-- BESPOKE -->
        <!-- <template v-slot:toggle-mobile-filters></template> -->
        <!-- END BESPOKE -->

      </utlz-sorting-options>

      <div class="list-items-wrapper utlz-row">
        <utlz-product-list-item @navigateToProductPage="navigateToProductPage($event)"
          v-for="product in products"
          :key="product.id"
          :product="product"
          :blockSize="blockSize"
          class="product-list-item"></utlz-product-list-item>
      </div>
      <utlz-pagination v-if="totalPages > 1 && !noProductsFound" :totalPages="totalPages" :currentPage="currentPage" :scrollToTop="true" @changePage="changePage($event)"></utlz-pagination>

      <div v-if="noProductsFound" class="no-products-found">
        <h2 v-translation="{ type: 'label', code: 'label_no_products_found'}"></h2>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import SortingOptions from 'src/components/webshop/sorting-options/SortingOptions.vue';
import ProductListItem from 'src/components/webshop/products/product-list-item/ProductListItem.vue';
import Pagination from 'src/components/ui/pagination/Pagination.vue';
export default {
  components: {
    'utlz-sorting-options': SortingOptions,
    'utlz-pagination': Pagination,
    'utlz-product-list-item': ProductListItem
  },
  // BESPOKE DEN
  // Added searchtext property as v-model property
  data () {
    return {
      searchText: ''
    }
  },
  // END BESPOKE
  computed: {
    ...mapState('elastic', ['products', 'filters', 'stringProperties', 'layoutType', 'blockSize', 'pageSizes', 'sortingOptions']),
    ...mapGetters('elastic', ['pageTitle', 'totalPages', 'currentPage', 'pageRange', 'totalProducts', 'noProductsFound']),
    // BESPOKE DEN-DI: Added placeholder text
    placeholderText () {
      return `${window.vue.translations.label.label_search_in_context} ${this.pageTitle}...`;
    }
    // END BESPOKE
  },
  methods: {
    // BESPOKE: Added setQueryString
    ...mapActions('elastic', ['initElastic', 'updateFilters', 'changePage', 'changeLayout', 'changePageSize', 'toggleMobileFilters', 'setQueryString', 'changePageSorting']),
    searchByQuery(searchText) {
      this.setQueryString(searchText);
      document.activeElement.blur();
    },
    // END BESPOKE
    navigateToProductPage (href) {
      location.href = href;
      localStorage.setItem('scrollPositionTop', window.pageYOffset);
    }
  },
  created () {
    this.initElastic();
  },
  updated () {
    window.scrollTo(0, localStorage.getItem('scrollPositionTop'));
    localStorage.setItem('scrollPositionTop', 0);
  }
};
</script>

<style>
</style>
